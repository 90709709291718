<template>
	<van-nav-bar left-arrow>
		<template #left>
			<img width="28px" height="28px" src="~@/assets/img/order/back-left.png" alt="" @click="goBack(1)">
		</template>
		<template #title>
			<div class="search-top">
				<slot />
			</div>
		</template>
		<!-- <template #right>
      <img width="28px" height="28px" src="~@/assets/img/backTop/cate-home.png" alt="" @click="goBack(2)">
    </template> -->
		<template #right>
			<div @click="showManageController" v-if="showManage">管理</div>
			<div @click="showManageController" v-else>完成</div>
		</template>
	</van-nav-bar>
</template>

<script>
	export default {
		name: 'TopNavBar',
		data() {
			return {
				searchName: '',
				showManage: true
			}
		},
		methods: {
			goBack(type) {
				switch (type) {
					case 1:
						this.$router.go(-1)
						break
					case 2:
						this.$router.push({
							name: 'Home',
							query: {
								shopUuid: this.$store.state.shopUuid
							}
						})
						break
				}
			},
			showManageController() {
				this.showManage = !this.showManage;
				this.$emit('onClick', this.showManage)
			}
		}
	}
</script>

<style lang="less" scoped>
	.search-top {
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #323232;
	}

	.home-logo {
		height: 30px;
		margin-top: 8px;
	}

	.home-left,
	.home-right {
		width: 29px;
		height: 29px;
	}

	[class*="van-hairline"]::after {
		border: none;
	}
</style>
