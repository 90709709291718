import { render, staticRenderFns } from "./collection.vue?vue&type=template&id=1b94a50a&scoped=true"
import script from "./collection.vue?vue&type=script&lang=js"
export * from "./collection.vue?vue&type=script&lang=js"
import style0 from "./collection.vue?vue&type=style&index=0&id=1b94a50a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b94a50a",
  null
  
)

export default component.exports