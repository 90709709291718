<template>
	<div class="recommend-container">
		<div class="product-list">
			<ProductCard v-for="(item,index) in proList" :key="index" :info="item"  @getInfo="goProDetail" />
		</div>
	</div>
</template>

<script>
import { setPageTitle } from '@/utils';
import { getFileList, clickPrtAddOne, addToCart } from '@/api';
import ProductCard from '@c/common/ProductCard.vue'
import { Toast } from 'vant';
import _ from 'lodash';
export default {
	name: 'RecommendList',
	components: {
		ProductCard
	},
	props: {
		disabled: {
			default: true
		},
		proList: {
			default: []
		},
		showFoot: {
			default: false
		}
	},
	computed: {
		hasVideo() {
			if (this.proList.length > 0) {
				const picList = this.proList?.picList || [];
				const video = picList.filter(item => item.typeName == 'prtVideo');
				return video.length > 0;
			} else {
				return false;
			}
		},

		// 视频图片
		videoPictureList() {
			const picList = this.info?.picList || [];
			return picList.filter(o => o.typeName == 'videoPicture');
		},
		// 产品详情列表--------------------------------------------
		prtDetailList() {
			const picList = this.info?.picList || [];
			return picList.filter(o => o.typeName == 'prtInfo');
		},
		// 生成视频左下角信息
		getVideoInfo() {
			return _.reduce(
				this.info,
				(result, value, key) => {
					if (value) {
						switch (key) {
							case 'prtName':
								if (this.info.isName == 0) {
									result.push({ label: '名称', value });
								}
								break;
							case 'prtCode':
								if (this.info.isCode == 0) {
									result.push({ label: '货号', value });
								}
								break;
							case 'prtType':
								if (this.info.isType == 0) {
									result.push({ label: '分类', value });
								}
								break;
							case 'prtLong':
								if (this.info.isChiCun == 0) {
									result.push({
										label: '尺寸',
										value: `${value}cm*${this.info.prtWide}cm*${this.info.prtHigh}cm`
									});
								}
								break;
							case 'prtSpecs':
								if (this.info.isSpecs == 0) {
									result.push({ label: '规格', value });
								}
								break;
							case 'prtContent':
								if (this.info.isContent == 0) {
									result.push({ label: '含量', value });
								}
								break;
							case 'prtHairNum':
								if (this.info.isHairNum == 0) {
									result.push({ label: '发数', value: value + '发' });
								}
								break;
							case 'prtInch':
								if (this.info.isInch == 0) {
									result.push({ label: '寸数', value: value + '寸' });
								}
								break;
							case 'prtWeight':
								if (this.info.isWeight == 0) {
									result.push({ label: '重量', value: value + 'kg' });
								}
								break;
							case 'prtEffect':
								if (this.info.isEffect == 0) {
									result.push({ label: '效果', value });
								}
								break;
						}
					}
					return result;
				},
				[]
			);
		}
	},
	methods: {
		// 产品图片列表--------------------------------------------
		prtPictureList(info) {
			const picList = info?.picList || [];
			const img = picList.filter(o => o.typeName == 'prtPicture');
			return img[0].filePath;
		},
		addToCart(info) {
			let param = {
				num: 1,
				productId: info.uuid,
				shopUuid: this.$store.state.shopUuid,
				userId: this.$store.state.userInfo.uuid // 分类名
			};
			addToCart(param).then(res => {
				if (res.state == 100) {
					Toast.success('成功加入购物车');
				}
			});
		},
		async handleVideoPlay() {
			if (!this.hasVideo) return;
			this.prtVideoList = await this.getFileList();
			// 增加视频点击量
			clickPrtAddOne({ uuid: this.info.uuid });
			const videoArr = this.prtVideoList.map((item, index) => {
				const oneVideo = {
					id: index,
					bgImg: this.info.isPic == 0 ? this.videoPictureList[0]?.filePath ?? '' : '',
					isLoad: index == 0,
					sources: item.filePath || '',
					infoArr: this.getVideoInfo,
					uuid: this.info.uuid,
					isLike: this.info.isLike
				};
				return oneVideo;
			});
			localStorage.setItem('videoArr', JSON.stringify(videoArr));
			this.$router.push({
				name: 'VideoPlay',
				query: {
					uuid: this.info.uuid,
					type: 'noClose',
					title: this.info.prtName
				}
			});
		},
		goProDetail(info) {
			let data = {
				uuid: info.uuid,
				title: info.prtName,
				typeBg: info.typeBg,
				shopUuid: this.$store.state.shopUuid
			};
			this.$emit('getInfos', data);
		},

		// 获取视频
		getFileList() {
			const params = {
				page: 0,
				pageSize: 0,
				typeName: 'prtVideo',
				parentUuid: this.info.uuid
			};
			return getFileList(params).then(res => {
				if (res.state == 100) {
					return res.items;
					// this.prtVideoList = res.items
				}
			});
		},
		handleDelete() {
			this.$emit('handleDelete', this.info);
		},
		refreshScroll() {
			this.$emit('refreshScroll');
		},
		handleDelete(info) {
			this.$emit('handleDelete', info);
		},
		changeSingeCheck() {
			let checkNum = [];
			this.proList.map(resp => {
				if (resp.checked) {
					checkNum.push(resp);
				}
			});
			if (checkNum.length == this.proList.length) {
				this.checkAll = true;
			} else {
				this.checkAll = false;
			}
			this.$forceUpdate();
			this.$emit('checkNum', checkNum);
		}
	}
};
</script>

<style lang="less" scoped>
.recommend-container {
	// padding: 7px 0;
	.product-list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 10px;
	}
}
.product-container {
	width: 48%;
}
ul {
	list-style: none;
	counter-reset: count;
	padding-top: 6px;
}
li {
	color: #969696;
	font-size: 11px;
	padding-left: 8px;
	display: flex;
	line-height: 18px;
}
li::before {
	content: '';
	color: #36C9FF;
	font-family: Microsoft YaHei;
	font-weight: 400;
	line-height: 20px;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.circle {
	height: 5px; /*no*/
	width: 5px; /*no*/
	border-radius: 50% !important;
	background-color: #36C9FF;
}
.card {
	width: 100%;
	background: #ffffff;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	// margin-top: 10px;
	padding-bottom: 10px;
	position: relative;
	.item-img {
		height: 175px;
		top: 0px;
		left: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-size: 100% 100%;
		border-radius: 5px 5px 0 0;
		img {
			width: 100%;
			height: 100%;
			border-radius: 5px 5px 0 0;
		}
	}
	.title {
		font-size: 13px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		padding-left: 8px;
		padding-top: 10px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
.item-btn {
	width: 100%;
	height: 46px;
	/*box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);*/
	// border-radius: 16px;
	overflow: hidden;
	position: relative;
	margin-top: 7px;
	.btn-left,
	.btn-right {
		/*flex: 1;*/
		display: flex;
		align-items: center;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffffff;
		img {
			width: 17px;
			height: 17px;
		}
		span {
			height: 17px;
			line-height: 18px;
			color: #ffffff;
		}
	}

	.btn-left {
		position: absolute;
		width: 55%;
		height: 33px;
		left: 1px;
		top: 0;
		z-index: 99;
		padding-left: 15px;
		background: url('../../../assets/img/home/1.png') no-repeat;
		background-size: 100% 100%;
		//  background: linear-gradient(90deg, #8be73f 0%, #4d5af1 0%, #636fff 100%);
	}

	.btn-right {
		position: absolute;
		width: 55%;
		height: 33px;
		top: 0;
		z-index: 99;
		right: 3px;
		padding-left: 25px;
		background: url('../../../assets/img/home/2.png') no-repeat;
		background-size: 100% 100%;
		// background: linear-gradient(90deg, #b63cfb 0%, #ff3cf8 100%);
	}

	.no-video {
		background: url('../../../assets/img/home/3.png') no-repeat;
		background-size: 100% 100%;
		// background: linear-gradient(90deg, #838383 0%, #c3c3c3 100%);
	}
}
</style>
