<template>
	<div class="container">
		<div class="top-nav-bar">
			<TopNavBar @onClick="onClick">{{ title }}</TopNavBar>
		</div>
		<div class="pro-content-list">
			<RecommendList :pro-list="proList" @getInfos="getInfo" :showFoot="showFoot" @checkNum="checkNum" :disabled="disabled" :type="type" @handleDelete="handleDelete" />
		</div>
		<!-- 水印 -->
		<WaterMark />
		<div class="footer" v-if="showFoot">
			<van-checkbox style="width: 124px;margin-left: 20px;" v-model="checkAll" @click="checkAllProduct">全选</van-checkbox>
			<div class="flex-center"><div class="btn" style="background: #FF9C00;width: 125px;" @click="deleteCollections">删除</div></div>
		</div>
	</div>
</template>

<script>
import { getProList, getNewProduct, getCollList, getFootprint, reCollection,reCollections } from '@/api';
// import scroll from '@c/scroll/Scroll.vue'
import TopNavBar from './components/TopNavBar.vue';
import RecommendList from './components/RecommendList.vue';
import { Toast } from 'vant';
export default {
	name: 'ProList',
	components: {
		TopNavBar,
		RecommendList
		// scroll
	},
	data() {
		return {
			title: '我的收藏',
			type: 0,
			proList: [],
			disabled: false,
			// 0收藏 1喜欢
			likColl: 0,
			checkAll: false,
			showFoot:false,
			checkNums:[]
		};
	},

	mounted() {
		this.init();
	},

	methods: {
		getInfo(data) {
			this.$router.push({
				name: 'ProductDetails',
				query: data
			});
		},
		checkNum(data){
			this.checkNums = data;
		},
		onClick(m){
			this.showFoot = !m;
		},
		// 全选
		checkAllProduct() {
			this.proList.map(resp => {
				resp.checked = this.checkAll;
			});
			this.$forceUpdate();
		},
		deleteCollections() {
			let lists = [];
			this.checkNums.map(resp => {
				if (resp.checked) {
					lists.push(resp.uuid);
				}
			});
			let param = {
				slist: lists,
				state: 0
			};
			reCollections(param).then(res => {
				if (res.state == 100) {
					Toast.success('删除成功');
					this.init();
				}
			});
		},
		init() {
			this.type = 2;
			this.likColl = 0;
			this.getCollList();
			this.disabled = false;
			this.$forceUpdate();
		},

		// 0收藏1喜欢
		getCollList() {
			const params = {
				likColl: this.likColl,
				page: 1,
				pageSize: 1000,
				shopUuid: this.$store.state.shopUuid
			};
			getCollList(params).then(res => {
				if (res.state == 100) {
					res.items.map(resp =>{
						resp.checked = false
					})
					this.proList = res.items;
				}
			});
		},

		// 删除
		handleDelete(info) {
			const data = {
				likColl: this.likColl,
				prtUuid: info.uuid
			};
			reCollection().then(res => {
				if (res.state == 100) {
					this.init();
				}
			});
		},

		// 监听滚动事件
		contentScroll(position) {
			// 1.判断BackTop是否显示
			// this.isShowBackTop = -position.y > BACKTOP_DISTANCE;
			// 2.觉得tabControl是否吸顶
			// this.isTabFixed = -position.y > this.tabControl;
		},
		// 加载更多
		loadMore() {
			// this.getHomeGoods(this.currentType);
		},
		refreshScroll() {
			this.$nextTick(() => {
				this.$refs.scroll.refresh();
			});
		}
	}
};
</script>

<style lang="less" scoped>
.footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 60px;
	z-index: 999;
	background: #ffffff;
	box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-between;

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;

		.btn {
			width: 174px;
			height: 39px;
			background: #ff3879;
			border-radius: 20px;
			line-height: 39px;
			text-align: center;
			font-size: 15px;
			color: #ffffff;
			font-weight: 400;
			margin-right: 20px;
		}
	}
}
.container {
	// height: 100vh;
	overflow: auto;
	padding-top: 60px;
	&::before {
		content: '';
		display: table;
	}

	.top-nav-bar {
		position: fixed;
		top: 0;
		width: 100%;
		height: 60px;
		z-index: 999;
	}

	.pro-type {
		width: 100vw;
		overflow: hidden;
	}

	.pro-content-list {
		width: 100vw;
	}
	.content {
		padding-top: 7px;
		overflow: auto;
		// height: calc(100vh - 170px);
		.pro-content-list {
			padding-bottom: 30px;
		}
	}
}
</style>
